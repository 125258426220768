@tailwind base;
@tailwind components;
@tailwind utilities;

.object-cover {
  /* position: fixed; */
  left: 0;
  background-size: cover;
  background: no-repeat center;
  user-select: none;
  pointer-events: none;

  /*custom styles, for example grayscale appearance*/
  filter: grayscale(0.8);

  /*additional tweaks for positioning, read more in the link above*/
  height: 100%;
  min-width: 100%;
}